var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content9"},[_c('span',{staticClass:"font47 textaC fontBold in-block marginT100 width100"},[_vm._v(_vm._s(_vm.$t("C2.v153")))]),_c('ul',{class:[
      _vm.content9Data.type == 'F2'
        ? 'footer-listf2'
        : _vm.Multi
        ? 'footer-listc2M'
        : 'footer-listc2',
      _vm.Multi ? 'Mmargin' : '' ]},[_vm._l((_vm.content9Data.footer),function(item,index){return _c('li',{key:'footer' + index,class:_vm.Multi ? 'Mli' : 'Zli'},[_c('img',{class:'img' + index,staticStyle:{"margin-bottom":"0.5rem"},style:(_vm.Multi && _vm.content9Data.type == 'C2' && index == 4
            ? 'width:0.38rem;height:0.56rem'
            : _vm.Multi && index == 4 && _vm.content9Data.type == 'F2'
            ? 'width:1.15rem;height:1.57rem'
            : !_vm.Multi && index === 1 && _vm.content9Data.type == 'C2'
            ? 'margin-top:0.2rem'
            : ''),attrs:{"src":item.img,"alt":""}}),_c('span',{staticClass:"color58 fw400 font24",style:(_vm.Multi ? 'display:inline-block' : ''),attrs:{"id":"span"}},[_vm._v(_vm._s(_vm.$t(item.text1)))])])}),_c('br',{staticStyle:{"clear":"both"}})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }