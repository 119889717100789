<template>
  <div class="content9">
    <span class="font47 textaC fontBold in-block marginT100 width100">{{
      $t("C2.v153")
    }}</span>
    <ul
      :class="[
        content9Data.type == 'F2'
          ? 'footer-listf2'
          : Multi
          ? 'footer-listc2M'
          : 'footer-listc2',
        Multi ? 'Mmargin' : '',
      ]"
    >
      <li
        :class="Multi ? 'Mli' : 'Zli'"
        v-for="(item, index) in content9Data.footer"
        :key="'footer' + index"
      >
        <img
          style="margin-bottom: 0.5rem"
          :style="
            Multi && content9Data.type == 'C2' && index == 4
              ? 'width:0.38rem;height:0.56rem'
              : Multi && index == 4 && content9Data.type == 'F2'
              ? 'width:1.15rem;height:1.57rem'
              : !Multi && index === 1 && content9Data.type == 'C2'
              ? 'margin-top:0.2rem'
              : ''
          "
          :src="item.img"
          :class="'img' + index"
          alt=""
        />
        <span
          id="span"
          :style="Multi ? 'display:inline-block' : ''"
          class="color58 fw400 font24"
          >{{ $t(item.text1) }}</span
        >
      </li>
      <br style="clear: both" />
    </ul>
  </div>
</template>

<script>
export default {
  name: "footerList",
  props: ["content9Data"],
  data() {
    return {
      Multi: false,
    };
  },
  mounted() {
    if (!["zh-cn", "zh-hk"].includes(localStorage.getItem("lans"))) {
      this.Multi = true;
    }
  },
  methods: {},
};
</script>

<style lang="less">
@import "@/assets/less/common.less";
.content9 {
  background: #f9f9f9;

  .footer-listc2 {
    margin-left: 2.75rem;
    margin-right: 2.75rem;
    padding-bottom: 0.7rem;
    .Zli {
      float: left;
      display: flex;
      flex-direction: column;
      width: 25%;
      text-align: center;
      align-items: center;
      height: 2.5rem;
      justify-content: flex-end;
      margin-top: 1rem;
    }
    .Mli {
      float: left;
      display: flex;
      flex-direction: column;
      width: 33%;
      text-align: center;
      align-items: center;
      height: 2.5rem;
      justify-content: flex-end;
      margin-top: 1rem;
    }

    .img0 {
      width: 2.14rem;
      height: 1.53rem;
    }
    .img1 {
      width: 1.22rem;
      height: 1.05rem;
    }
    .img2 {
      width: 1.38rem;
      height: 1.06rem;
    }
    .img3 {
      width: 1.97rem;
      height: 0.75rem;
    }
    .img4 {
      width: 1.39rem;
      height: 0.84rem;
    }
    .img5 {
      width: 0.61rem;
      height: 0.9rem;
    }
    .img6 {
      width: 1.17rem;
      height: 1.59rem;
    }
    .img7 {
      width: 1.66rem;
      height: 1.13rem;
    }
    .img8 {
      width: 1.17rem;
      height: 1.59rem;
    }
  }
  .footer-listc2M {
    margin-left: 2.75rem;
    margin-right: 2.75rem;
    padding-bottom: 0.7rem;

    .Mli {
      float: left;
      display: flex;
      flex-direction: column;
      width: 33%;
      text-align: center;
      align-items: center;
      height: 2.5rem;
      justify-content: flex-end;
      margin-top: 1rem;
    }
    .Zli {
      float: left;
      display: flex;
      flex-direction: column;
      width: 25%;
      text-align: center;
      align-items: center;
      height: 2.5rem;
      justify-content: flex-end;
      margin-top: 1rem;
    }
    .img0 {
      width: 2.14rem;
      height: 1.53rem;
    }
    .img1 {
      width: 1.22rem;
      height: 1.05rem;
    }
    .img2 {
      width: 1.38rem;
      height: 1.06rem;
    }
    .img3 {
      width: 1.97rem;
      height: 0.75rem;
    }
    .img4 {
      width: 1.39rem;
      height: 0.84rem;
    }
    .img5 {
      width: 1.15rem;
      height: 1.57rem;
    }
    .img6 {
      width: 1.17rem;
      height: 1.59rem;
    }
    .img7 {
      width: 1.66rem;
      height: 1.13rem;
    }
    .img8 {
      width: 1.17rem;
      height: 1.59rem;
    }
  }

  .footer-listf2 {
    margin-left: 2.75rem;
    margin-right: 2.75rem;
    padding-bottom: 0.7rem;
    .Zli {
      float: left;
      display: flex;
      flex-direction: column;
      width: 25%;
      text-align: center;
      align-items: center;
      height: 2.5rem;
      justify-content: flex-end;
      margin-top: 1rem;
    }
    .Mli {
      float: left;
      display: flex;
      flex-direction: column;
      width: 33%;
      text-align: center;
      align-items: center;
      height: 2.5rem;
      justify-content: flex-end;
      margin-top: 1rem;
    }
    .img0 {
      width: 2.14rem;
      height: 1.53rem;
    }
    .img1 {
      width: 1.22rem;
      height: 1.05rem;
    }
    .img2 {
      width: 1.38rem;
      height: 1.06rem;
    }
    .img3 {
      width: 1.97rem;
      height: 0.75rem;
    }
    .img4 {
      width: 1.17rem;
      height: 1.59rem;
    }
    .img5 {
      width: 1.66rem;
      height: 1.13rem;
    }
  }
  .Mmargin {
    margin-left: 2.2rem;
    margin-right: 2.2rem;
  }
}
</style>