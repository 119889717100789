<template>
  <div class="content8">
    <span class="font47 fontBold color1A textaC in-block width100">{{
      $t(content8Data.title)
    }}</span>
    <div :class="content8Data.type === 'F2' ? 'item1F' : 'item1C'">
      <img :src="content8Data.img1" alt="" />
      <img :src="content8Data.img2" alt="" />
    </div>
    <div class="item2 marginT100 fontBold font24">
      <div>
        <span class="op6 color0 in-block">
          {{ $t("C2.v121") }}
        </span>
        <div class="line"></div>
        <template v-for="(item, index) in content8Data.tableBottom">
          <div
            class="grid"
            :key="'grid' + index"
            :class="[14, 15].includes(index) ? 'bor0' : ''"
          >
            <span class="in-block">{{ $t(item.text1) }}</span>
            <span class="color58 fw400">{{ $t(item.text2) }}</span>
          </div>
        </template>
        <br style="clear: both" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "list",
  props: ["content8Data"],
  data() {
    return {};
  },

  mounted() {},
};
</script>

<style lang="less">
@import "@/assets/less/common.less";
.content8 {
  > span:nth-child(1) {
    margin-top: 0.8rem;
  }
  .item1C {
    margin-left: 1.8rem;
    img {
      height: 4.8rem;
    }
    > img:nth-child(2) {
      margin-bottom: -0.7rem;
    }
  }
  .item1F {
    margin-left: 1.8rem;
    margin-top: 0.5rem;
    > img:nth-child(1) {
      height: 4.44rem;
    }
    > img:nth-child(2) {
      margin-left: 2.34rem;
      height: 2.95rem;
    }
  }
  .item2 {
    margin-left: 2.07rem;
    margin-right: 2.07rem;
    > div {
      > span:nth-child(1) {
        margin-bottom: 0.24rem;
      }
      .line {
        height: 0.01rem;
        background: #000000;
        opacity: 0.2;
      }
      .grid {
        line-height: 0.78rem;
        height: 0.78rem;
        width: 7.2rem;
        border-bottom: 0.07px solid rgba(0, 0, 0, 0.1);
        float: left;
        > span:nth-child(1) {
          width: 2.5rem;
        }
      }

      .bor0 {
        border-bottom: 0;
      }
    }
  }
  margin-bottom: 0.62rem;
}
</style>