var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.multiLanguage ? 'contentMul' : 'content1',attrs:{"id":"co1"}},[_c('div',{staticClass:"c1"},[_c('div',{staticClass:"item c1-item1"},[_c('div',{staticClass:"c1-item1-1"}),_c('div',{class:[
          _vm.obj.type === 'F2'
            ? 'margin1'
            : _vm.multiLanguage
            ? 'margin3'
            : 'margin2',
          _vm.multiLanguage ? 'Mc1-item1-2' : 'c1-item1-2' ]},[_c('img',{class:_vm.obj.type === 'F2' ? 'img2' : 'img1',attrs:{"src":_vm.obj.imgLeft,"alt":""}})]),_c('div',{staticClass:"c1-item1-1"})]),_c('div',{staticClass:"item c1-item2"},[_c('div',[_c('div',{staticClass:"p1"},[_vm._v(_vm._s(_vm.$t(_vm.obj.textTitle)))]),_c('div',{staticClass:"line"}),_c('span',{staticClass:"color"},[_vm._v(" "+_vm._s(_vm.$t(_vm.obj.color)))]),_c('div',{staticClass:"option"},[_c('div',{staticClass:"option-1"},[_c('div'),_c('div',[_vm._v(_vm._s(_vm.$t(_vm.obj.option1)))])])])])])]),_c('div',{staticClass:"c2"},[_c('div',{style:(_vm.multiLanguage
          ? 'width: 100%;margin-left: 1.7rem;display: flex;justify-content: space-between;margin-right: 1.7rem;'
          : '')},[_vm._l((_vm.obj.info),function(item,index){return [_c('div',{key:'c2-' + index,staticClass:"c2-item",style:(_vm.multiLanguage ? 'margin-left:unset' : '')},[_c('div',{staticClass:"color3A"},[_c('div',{class:_vm.obj.type == 'F2' ? 'img2' : 'img1',style:(_vm.multiLanguage && index === 3 ? 'display: flex;' : '')},[_c('img',{class:'img-1' + index,attrs:{"src":item.img1,"alt":""}})]),_c('span',{staticClass:"fw400"},[_vm._v(_vm._s(_vm.$t(item.text1)))])]),(item.text2)?_c('div',[_c('div',{class:_vm.obj.type == 'F2' ? 'img2' : 'img1'},[_c('img',{class:'img-2' + index,attrs:{"src":item.img2,"alt":""}})]),(index !== 3)?_c('span',{staticClass:"fw400"},[_vm._v(" "+_vm._s(_vm.$t(item.text2))+" ")]):_c('span',{staticClass:"sp1 fw400"},[_c('span',{staticClass:"sp2"},[_vm._v(_vm._s(_vm.$t(item.text2))+" "),(_vm.obj.type === 'C2')?_c('sup',{staticClass:"font15",staticStyle:{"margin-left":"-0.05rem","position":"absolute","margin-top":"-0.05rem"}},[_vm._v("「1」")]):_vm._e()])])]):_vm._e()])]})],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }