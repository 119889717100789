<template>
  <div :class="multiLanguage ? 'contentMul' : 'content1'" id="co1">
    <div class="c1">
      <div class="item c1-item1">
        <div class="c1-item1-1">
          <!-- <img
              src="@/assets/image/accessories/arrows-left.png"
              alt=""
            /> -->
        </div>
        <div
          :class="[
            obj.type === 'F2'
              ? 'margin1'
              : multiLanguage
              ? 'margin3'
              : 'margin2',
            multiLanguage ? 'Mc1-item1-2' : 'c1-item1-2',
          ]"
        >
          <img
            :class="obj.type === 'F2' ? 'img2' : 'img1'"
            :src="obj.imgLeft"
            alt=""
          />
        </div>
        <div class="c1-item1-1">
          <!-- <img
              src="@/assets/image/accessories/arrows-right.png"
              alt=""
            /> -->
        </div>
      </div>
      <div class="item c1-item2">
        <div>
          <div class="p1">{{ $t(obj.textTitle) }}</div>
          <!-- <div class="plice">{{ obj.plice }}</div> -->
          <div class="line"></div>
          <span class="color"> {{ $t(obj.color) }}</span>
          <div class="option">
            <div class="option-1">
              <div></div>
              <div>{{ $t(obj.option1) }}</div>
            </div>
            <!-- <div class="option-1">
              <div></div>

              
              <div>经典黑</div>
            </div> -->
          </div>
          <!-- <div class="line"></div> -->
          <!--<button>立即购买</button>-->
        </div>
      </div>
    </div>
    <div class="c2">
      <div
        :style="
          multiLanguage
            ? 'width: 100%;margin-left: 1.7rem;display: flex;justify-content: space-between;margin-right: 1.7rem;'
            : ''
        "
      >
        <template v-for="(item, index) in obj.info">
          <div
            :style="multiLanguage ? 'margin-left:unset' : ''"
            class="c2-item"
            :key="'c2-' + index"
          >
            <div class="color3A">
              <div
                :style="multiLanguage && index === 3 ? 'display: flex;' : ''"
                :class="obj.type == 'F2' ? 'img2' : 'img1'"
              >
                <img :class="'img-1' + index" :src="item.img1" alt="" />
              </div>
              <span class="fw400">{{ $t(item.text1) }}</span>
            </div>
            <div v-if="item.text2">
              <div :class="obj.type == 'F2' ? 'img2' : 'img1'">
                <img :class="'img-2' + index" :src="item.img2" alt="" />
              </div>
              <span v-if="index !== 3" class="fw400">
                {{ $t(item.text2) }}
              </span>
              <span class="sp1 fw400" v-else>
                <span class="sp2"
                  >{{ $t(item.text2) }}
                  <sup
                    style="
                      margin-left: -0.05rem;
                      position: absolute;
                      margin-top: -0.05rem;
                    "
                    v-if="obj.type === 'C2'"
                    class="font15"
                    >「1」</sup
                  ></span
                >
              </span>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "contentOne",
  data() {
    return {
      obj: {
        type: "",
        imgLeft: "",
        textTitle: "",
        plice: "",
        color: "",
        option1: "",
        info: [],
        infoMulti: [],
      },
      multiLanguage: false,
      isKO: false,
    };
  },
  props: ["content1Data"],
  mounted() {
    this.multiLanguage = !["zh-hk", "zh-cn"].includes(
      localStorage.getItem("lans")
    );
    this.obj = {
      type: this.content1Data.type,
      imgLeft: this.content1Data.imgLeft,
      textTitle: this.content1Data.textTitle,
      plice: this.content1Data.plice,
      color: this.content1Data.color,
      option1: this.content1Data.option1,
      info: this.content1Data.info,
    };
    if (!["zh-hk", "zh-cn"].includes(localStorage.getItem("lans"))) {
      this.obj.info = JSON.parse(JSON.stringify(this.content1Data.infoMulti));
      if (localStorage.getItem("lans") === "ko") {
        this.isKO = true;
      }
    }
  },
};
</script>

<style lang="less">
@import "@/assets/less/common.less";
.content1 {
  width: 100%;

  .c1 {
    margin-left: 1.76rem;
    margin-right: 1.76rem;
    height: 6.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0.1rem;

    .item {
      height: 100%;
      flex: 1;
    }
    .c1-item1 {
      margin-bottom: 0.72rem;
      display: flex;
      justify-content: center;
      align-items: center;

      .c1-item1-1 {
        height: 100%;
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center;

        img {
          display: block;
          float: right;
          width: 0.14rem;
          height: 0.26rem;
        }
      }
      .margin1 {
        margin-top: -1.35rem;
      }
      .margin2 {
        margin-top: -1.15rem;
      }
      .c1-item1-2 {
        height: 100%;
        flex: 2;

        .img1 {
          width: 7.64rem;
          height: 6.02rem;
        }
        .img2 {
          width: 5.72rem;
          height: 6rem;
        }
      }
    }
    .c1-item2 {
      margin-bottom: 0.72rem;
      display: flex;
      flex-direction: column;
      color: #4d4d4d;
      position: relative;

      > div {
        position: absolute;
        right: 0;

        .p1 {
          width: 5.99rem;
          font-size: 0.4rem;
          font-family: MI LANTING;
          font-weight: bold;
          color: #4d4d4d;
        }
        .top {
          height: 100%;
          padding-top: 1rem;
        }

        .plice {
          width: 5.99rem;
          margin-top: 0.4rem;
          font-size: 0.52rem;
          font-family: MI LANTING;
          font-weight: 400;
          color: #4d4d4d;
        }
        .line {
          width: 5.99rem;
          height: 0.01rem;
          margin-top: 0.4rem;
          background: #8d8d8d;
          border: 0rem solid #030000;
        }
        .color {
          width: 5.99rem;
          display: inline-block;
          margin-top: 0.4rem;
          margin-bottom: 0.29rem;
          font-size: 0.24rem;
          font-family: MI LANTING;
          font-weight: bold;
          color: #4d4d4d;
        }
        .option {
          display: flex;
          align-items: center;

          .option-1 {
            width: 2.89rem;
            height: 1.51rem;
            font-size: 0.22rem;
            border: 0.01rem solid #8d8d8d;
            border-radius: 0.18rem;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            > div:nth-child(1) {
              width: 0.48rem;
              height: 0.48rem;
              background: #161616;
              border: 0rem solid #030000;
              border-radius: 50%;
            }
            > div:nth-childbuttery-small {
              font-size: 0.22rem;
              font-family: MI LANTING;
              font-weight: 400;
              color: #4d4d4d;
              margin: 0 auto;
            }
          }
        }
        button {
          width: 5.98rem;
          height: 0.79rem;
          background: #036fe3;
          border: 0rem solid #8d8d8d;
          border-radius: 0.18rem;
          color: #ffffff;
          font-size: 0.24rem;
          font-family: MI LANTING;
          font-weight: bold;
        }
      }
    }
  }
  .c2 {
    width: 100%;
    background: #f2f2f2;
    border: 0rem solid #030000;
    display: flex;
    font-size: 0.27rem;

    > div {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
      margin-right: 1.84rem;
      display: flex;
      margin-left: 1.84rem;
      justify-content: space-between;

      .c2-item {
        justify-content: space-evenly;
        height: 100%;
        display: flex;
        flex-direction: column;

        > div {
          align-items: center;
          display: flex;
          position: relative;

          .img1 {
            width: 0.51rem;
            text-align: center;
            .img-10 {
              width: 0.43rem;
              height: 0.4rem;
            }
            .img-20 {
              width: 0.44rem;
              height: 0.44rem;
            }
            .img-11 {
              width: 0.42rem;
              height: 0.32rem;
            }
            .img-13 {
              width: 0.51rem;
              height: 0.27rem;
            }
            .img-21 {
              width: 0.25rem;
              height: 0.51rem;
            }
            .img-12 {
              width: 0.43rem;
              height: 0.39rem;
            }
            .img-22 {
              width: 0.34rem;
              height: 0.45rem;
            }
            .img-23 {
              width: 0.47rem;
              height: 0.38rem;
            }
          }
          .img2 {
            width: 0.51rem;
            text-align: center;
            img {
              width: 0.44rem;
              height: 0.44rem;
            }
          }
          > span {
            display: inline-block;
            margin-left: 0.19rem;
            width: 3.2rem;
            height: 0.39rem;

            .one {
              font-size: 0.19rem;
              font-family: MI LANTING;
              font-weight: 400;
              line-height: 0.3rem;
              position: absolute;
            }
          }
        }
        > div:nth-child(1) {
          margin-bottom: 0.4rem;
        }
      }
    }
  }
}
.contentMul {
  width: 100%;

  .c1 {
    margin: 0 2rem 0 2rem;
    height: 6.15rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0.1rem;

    .item {
      height: 100%;
      flex: 1;
    }
    .c1-item1 {
      margin-bottom: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;

      .c1-item1-1 {
        height: 100%;
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center;

        img {
          display: block;
          float: right;
          width: 0.14rem;
          height: 0.26rem;
        }
      }
      .margin1 {
        margin-top: 0;
      }
      .margin2 {
        margin-top: 1.1rem;
      }
      .margin3 {
        margin-top: 0.1rem;
      }
      .c1-item1-2 {
        height: 100%;
        flex: 2;

        .img1 {
          width: 8.87rem;
          height: 6.99rem;
        }
        .img2 {
          width: 5.72rem;
          height: 6rem;
        }
      }
      .Mc1-item1-2 {
        height: 100%;
        flex: 2;

        .img1 {
          width: 7.64rem;
          height: 6.02rem;
        }
        .img2 {
          width: 5.72rem;
          height: 6rem;
        }
      }
    }
    .c1-item2 {
      display: flex;
      flex-direction: column;
      color: #4d4d4d;
      position: relative;

      > div {
        position: absolute;
        right: 0;

        .p1 {
          width: 5.25rem;
          font-size: 0.42rem;
          font-family: MI LANTING;
          font-weight: bold;
          color: #4d4d4d;
        }
        .top {
          height: 100%;
          padding-top: 1rem;
        }

        .plice {
          width: 4.64rem;
          margin-top: 0.21rem;
          font-size: 0.52rem;
          font-family: MI LANTING;
          font-weight: 400;
          color: #4d4d4d;
        }
        .line {
          width: 5.99rem;
          height: 0.01rem;
          margin-top: 0.32rem;
          background: #8d8d8d;
          border: 0rem solid #030000;
        }
        .color {
          width: 4.64rem;
          display: inline-block;
          margin-top: 0.3rem;
          margin-bottom: 0.22rem;
          font-size: 0.24rem;
          font-family: MI LANTING;
          font-weight: bold;
          color: #4d4d4d;
        }
        .option {
          display: flex;
          align-items: center;

          .option-1 {
            width: 2.89rem;
            height: 1.51rem;
            font-size: 0.22rem;
            border: 0.01rem solid #8d8d8d;
            border-radius: 0.18rem;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            > div:nth-child(1) {
              width: 0.48rem;
              height: 0.48rem;
              background: #161616;
              border: 0rem solid #030000;
              border-radius: 50%;
            }
            > div:nth-childbuttery-small {
              font-size: 0.22rem;
              font-family: MI LANTING;
              font-weight: 400;
              color: #4d4d4d;
              margin: 0 auto;
            }
          }
        }
        button {
          width: 5.98rem;
          height: 0.79rem;
          background: #036fe3;
          border: 0rem solid #8d8d8d;
          border-radius: 0.18rem;
          color: #ffffff;
          font-size: 0.24rem;
          font-family: MI LANTING;
          font-weight: bold;
        }
      }
    }
  }
  .c2 {
    width: 100%;
    background: #f2f2f2;
    border: 0rem solid #030000;
    display: flex;
    font-size: 0.24rem;

    > div {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;

      .c2-item {
        float: left;
        margin-left: 0.6rem;

        > div {
          align-items: center;
          display: flex;
          position: relative;

          .img1 {
            width: 0.51rem;
            text-align: center;
            .img-10 {
              width: 0.41rem;
              height: 0.38rem;
            }
            .img-13 {
              width: 0.49rem;
              height: 0.25rem;
            }
            .img-30 {
              width: 0.32rem;
              height: 0.43rem;
            }
            .img-11 {
              width: 0.4rem;
              height: 0.3rem;
            }
            .img-22 {
              width: 0.32rem;
              height: 0.43rem;
            }

            .img-12 {
              width: 0.41rem;
              height: 0.37rem;
            }
            .img-21 {
              width: 0.23rem;
              height: 0.49rem;
            }
            .img-20 {
              width: 0.42rem;
              height: 0.42rem;
            }
          }
          .img2 {
            width: 0.51rem;
            text-align: center;
            img {
              width: 0.44rem;
              height: 0.44rem;
            }
          }
          > span {
            display: inline-block;
            margin-left: 0.19rem;

            height: 0.39rem;

            .one {
              font-size: 0.19rem;
              font-family: MI LANTING;
              font-weight: 400;
              line-height: 0.3rem;
              position: absolute;
            }
          }
        }
        > div:nth-child(1) {
          margin-bottom: 0.53rem;
        }
      }
      & > div:nth-child(1) {
        margin-left: 1rem;
      }
    }
    .ko {
      margin-left: 1.2rem;
    }
  }
}
</style>